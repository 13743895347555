<template>
  <div class="auction-page">
    <Head title="我的竞拍" />
    <ul class="auction-nav" ref="nav">
      <span
        class="bar"
        :style="{ left: activeIndex * (100 / navList.length) + '%' }"
      ></span>
      <li
        @click.stop="activeIndex = index"
        :class="{ active: activeIndex == index }"
        v-for="(item, index) in navList"
        :key="index"
      >
        {{ item.text }}
      </li>
    </ul>
    <Warpper bottom="60" top="84">
      <div
        class="page-loadmore-wrapper"
        style="overflow: scroll; height: 100%"
        ref="wrapper"
      >
        <mt-loadmore
          :bottom-method="loadBottom"
          @bottom-status-change="handleBottomChange"
          :bottom-all-loaded="allLoaded"
          :auto-fill="false"
          ref="loadmore"
        >
          <ul class="page-loadmore-list auction-list">
            <li
              v-for="(item, index) in list"
              :key="index"
              @click="toDetail(item.id, 0)"
            >
              <div class="pic">
                <img v-lazy="item.pic" alt />
                <i v-if="activeIndex<3" :class="{'active':item.touser==userInfo.id}"></i>
              </div>
              <div class="info">
                <h5>{{ item.title }}</h5>
                <div class="price">
                  <p>
                    现价:
                    <span class="red">¥{{ item.now_price }}</span>
                    <span>（{{ item.touser_nickname }}）</span>
                  </p>
                  <p>
                    我的出价:
                    <span class="black">¥{{ item.price }}</span>
                  </p>
                </div>
                <div class="time">
                  <p v-if="activeIndex>2">结束时间：{{ item.end_time }}</p>
                  <p v-else>{{item.count_down}}</p>
                  <span class="status-b" v-if="activeIndex == 1">淘汰出局</span>
                  <span class="status-a" v-else @click="toDetail(item.id, 0)"
                    >查看拍品</span
                  >
                </div>
              </div>
            </li>
          </ul>

          <div slot="bottom" class="mint-loadmore-bottom">
            <span
              v-show="bottomStatus !== 'loading'"
              :class="{ 'is-rotate': bottomStatus === 'drop' }"
              >↑</span
            >
            <span v-show="bottomStatus === 'loading'">
              <mt-spinner type="snake"></mt-spinner>
            </span>
          </div>

          <None
            class="y-none"
            value="没有更多数据啦"
            v-if="allLoaded && list.length == 0"
          />
        </mt-loadmore>
      </div>
    </Warpper>
    <Tabber :selected="1" />
  </div>
</template>

<script>
import Tabber from "components/Tabbar";
import None from "components/None";
import { reqGetOrderList } from "@/network/api";
import MyMqtt from "@/plugins/mqtt";
export default {
  name: "auction",
  components: {
    Tabber,
    None,
  },
  created() {
    this.activeIndex = this.$route.query.index || 0;
    this.mqtt = new MyMqtt();
    this.mqtt.init();
    this.mqtt.message = this.onMessage;
  },
  mounted() {
    this.first = true;
    this.loadBottom();
    if(this.activeIndex<=2){
      this.createTimer()
    }
  },
  data() {
    return {
      userInfo:this.$store.getters.userInfo,
      timer:null,
      first: true,
      allLoaded: false,
      bottomStatus: "",
      wrapperHeight: 0,
      list: [],
      activeIndex: -1,
      page: 0,
      total: 1,
      countDownStr:"",
      navList: [
        { text: "在拍", value: "index" },
        { text: "出局", value: "out" },
        { text: "领先", value: "win" },
        { text: "结束", value: "end" },
        { text: "收藏", value: "fav" },
      ],
      contentH: 0,
      isSend:false
    };
  },
  methods: {
    createTimer(){
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.list = this.list.map(item=>{
          item.count_down = this.getCountDown(item.end_time.replace(/-/g, "/"),item.late_state);
          return item;
        })
        this.createTimer();
      },1000)
      
    },
    getCountDown(end,late){
      let _end = new Date(end);
      let _now = new Date();
      if(_now>_end) return this.countDownStr = "已结束";
      return this.changeDate(_end-_now)+(late==1?"(延时)":"");
    },
    changeDate(n) {
      let haoscend = n % 1000; //毫秒
      let scend = parseInt((n / 1000) % 60); //秒
      let minute = parseInt((n / 1000 / 60) % 60); //  分钟
      let hour = parseInt((n / 1000 / 60 / 60) % 24); //小时
      let day = Math.floor( n/1000 / (24*3600) ); //天数
      let txt = day > 0 ? `${day}天` : "";
      txt += hour>0 ? `${hour<10?"0"+hour:hour}小时`:"";
      txt += minute>0 ? `${minute<10?"0"+minute:minute}分`:"";
      txt += `${scend<10?"0"+scend:scend}秒`;
      return "距离结束："+txt;
    },
    onMessage(msg) {
      this.$store.dispatch("other/pushMqttList",msg);
      // if (this.navIndex == 1) return;
      this.list = [].map.call(this.list, item => {
        if (msg.text.id == item.id) {
          item = this.changeItem(msg, item);
          console.log(item);
          
        }
        return item;
      });
    },
    changeItem(msg, item) {
      let obj = item;
      const { action, text } = msg;
      if (action == "edit" || action == "expire") {
        for (const key in text) {
          if (text.hasOwnProperty(key)) {
            obj[key] = msg.text[key];
          }
        }
      }
      return obj;
    },
    toDetail(id, type) {
      // 跳转详情
      this.$router.push({
        name: "detail",
        query: {
          type,
          id,
        },
      });
    },
    handleBottomChange(status) {
      this.bottomStatus = status;
    },
    loadBottom() {
      if(this.isSend) return;
      this.isSend = true;
      if (this.page >= this.total) return;
      reqGetOrderList({
        action: this.navList[this.activeIndex].value,
        page: this.page + 1,
      }).then((res) => {
        this.isSend = false;
        const { code, msg, backdata } = res;
        if (code != 0) return;
        if (backdata) {
          this.page = backdata.now;
          this.total = backdata.count;
          this.list = [...this.list, ...backdata.list];
          this.list = this.list.map(item=>{
            item.count_down = this.getCountDown(item.end_time);
            return item;
          })
          if (this.page >= this.total) {
            this.allLoaded = true;
          }
        } else {
          this.allLoaded = true;
        }
        // if (!this.first) {
        //   this.$refs.loadmore.onBottomLoaded();
        // }
        // this.first = false;
      }).catch(()=>{
        this.isSend = false;
      });
    },
  },
  watch: {
    activeIndex(v) {
      if (this.$route.query.index == v) return;
      this.page = 0;
      this.total = 1;
      this.allLoaded = false;
      this.list.length = 0;
      this.$router.replace({
        name: "auction",
        query: {
          index: v,
        },
      });
      this.first = true;
      this.loadBottom();
    },
  },
};
</script>

<style lang="scss" scoped>
.auction-list > li {
  box-sizing: border-box;
  padding: 0.45rem 0.3rem;
  display: flex;
  position: relative;
  
  &:not(:last-of-type)::after {
    content: "";
    display: block;
    bottom: 0;
    width: 6.9rem;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(0.5);
  }
  .pic {
    width: 1.32rem;
    height: 1.32rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    overflow: hidden;
    display: block;
    border-radius: 0.08rem;
    margin-right: 0.24rem;
    position: relative;
    background-color: #e5e5e5;
    i{
    width: .2rem;
    height: .24rem;
    background: url("../../assets/images/icon_gray.png") no-repeat;
    background-size: 100% auto;
    position: absolute;
    left: .06rem;
    top: 0;
    &.active{
      background: url("../../assets/images/icon_orange.png") no-repeat;
      background-size: 100% auto;
    }
  }
    img {
      width: 1.32rem;
      height: 1.32rem;
      object-fit: cover;
      &[lazy="loading"] {
        width: 0.5rem;
        height: 0.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #e5e5e5;
      }
    }
  }
  .info {
    flex: 1;
    h5 {
      font-size: 0.26rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 0.39rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 0.28rem;
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.27rem;
      p {
        font-size: 0.26rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.39rem;
        .red {
          color: #ff1010;
        }
        .black {
          color: #333333;
        }
      }
    }
    .time {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.39rem;
        .black {
          color: #333333;
        }
      }
      span {
        width: 1.26rem;
        height: 0.44rem;

        border-radius: 0.04rem;
        font-size: 0.24rem;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        &.status-a {
          background: #62ada2;
        }
        &.status-b {
          background: #a0a0a0;
        }
      }
    }
  }
}
.mint-loadmore-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.auction-nav {
  width: 100%;
  display: flex;
  align-items: center;
  background: #62ada2;
  height: 44px;
  position: relative;
  li {
    position: relative;
    cursor: pointer;
    flex: 1;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      color: #fffe00;
    }
  }
  .bar {
    content: "";
    width: (100%/5);
    position: absolute;
    bottom: 0;
    left: (100%/5) * -1;
    height: 3px;
    background: linear-gradient(90deg, #fffe00 0%, #fec600 100%);
    z-index: 9;
    transition: 0.12s left ease-out;
  }
}
</style>